<template>
  <div>
    <md-table md-card table-header-color="blue" :value="skills">
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">Таблица скиллов</h1>
        </div>
      </md-table-toolbar>

      <md-table-empty-state md-label="Список пуст" />

      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="ID">
          {{ item.skillId }}
        </md-table-cell>

        <md-table-cell md-label="Название">
          {{ item.name }}
        </md-table-cell>

        <md-table-cell md-label="Описание" :title="item.description">
          {{ item.description | truncate(40, "...") }}
        </md-table-cell>

        <md-table-cell md-label="Кулдаун">
          {{ item.cooldown }}
        </md-table-cell>

        <md-table-cell md-label="Дальность">
          {{ item.range }}
        </md-table-cell>

        <md-table-cell md-label="Макс.уровень">
          {{ item.maxSkillLevel }}
        </md-table-cell>

        <md-table-cell md-label="Баффы">
          <span
            v-for="buffId in item.buffs"
            :key="`buff_${buffId}`"
            class="link"
            @click="openBuffModal(buffId)"
          >
            {{ buffsById[buffId].name }} <br />
          </span>
        </md-table-cell>

        <md-table-cell>
          <md-button
            class="md-just-icon md-simple md-primary"
            @click="$router.push(`/skills/${item.skillId}`)"
          >
            <md-icon>edit</md-icon>
            <md-tooltip md-direction="left">Изменить</md-tooltip>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <BuffModal
      v-if="showModal && !!selectedBuff"
      :item="selectedBuff"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length <= length) {
        return text.substring(0, length);
      } else {
        return text.substring(0, length) + suffix;
      }
    },
  },

  components: {
    BuffModal: () => import("@/components/Modals/BuffModal"),
  },

  data() {
    return {
      selectedBuff: null,
      showModal: false,
    };
  },

  computed: {
    ...mapGetters("buffs", ["buffsById"]),
    ...mapState("skills", ["skills"]),
  },

  async mounted() {
    this.showLoader(true);
    await this.getBuffs();
    await this.getSkills();
    this.showLoader(false);
  },

  methods: {
    ...mapActions("buffs", ["getBuffs"]),
    ...mapActions("skills", ["getSkills"]),
    ...mapMutations({
      showLoader: "SET_SHOW_LOADER",
    }),

    closeModal() {
      this.showModal = false;
      this.selectedBuff = null;
    },

    openBuffModal(buffId) {
      this.selectedBuff = JSON.parse(JSON.stringify(this.buffsById[buffId]));
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  color: #9c27b0;
  cursor: pointer;

  &:hover {
    color: #5a1566;
  }
}

::v-deep {
  .md-table-cell-container {
    white-space: nowrap;
  }
}
</style>
